import i18n from '@/i18n/i18n'

export const getOptionsTime = () => {
    return [
        { value: 0, text: i18n.t('general.all_day') },
        { value: 1, text: i18n.t('general.select_time') }
    ]
}

export const getRepeatInterval = () => {
    return [
        {value: 'daily', text: i18n.t('general.daily')},
        {value: 'weekly', text: i18n.t('general.weekly')},
        {value: 'monthly', text: i18n.t('general.monthly')}
    ]
}

export const getWeekdays = () => {
    return [
        {value: 1, text: i18n.t('general.mon'), textFull: i18n.t('general.monday')},
        {value: 2, text: i18n.t('general.tue'), textFull: i18n.t('general.tuesday')},
        {value: 3, text: i18n.t('general.wed'), textFull: i18n.t('general.wednesday')},
        {value: 4, text: i18n.t('general.thu'), textFull: i18n.t('general.thursday')},
        {value: 5, text: i18n.t('general.fri'), textFull: i18n.t('general.friday')},
        {value: 6, text: i18n.t('general.sat'), textFull: i18n.t('general.saturday')},
        {value: 0, text: i18n.t('general.sun'), textFull: i18n.t('general.sunday')}
    ]
}

export const getDaysOptions = () => {
    return [
        {value: 1, text: '1'},
        {value: 2, text: '2'},
        {value: 3, text: '3'},
        {value: 4, text: '4'},
        {value: 5, text: '5'},
        {value: 6, text: '6'},
        {value: 7, text: '7'},
        {value: 8, text: '8'},
        {value: 9, text: '9'},
        {value: 10, text: '10'},
        {value: 11, text: '11'},
        {value: 12, text: '12'},
        {value: 13, text: '13'},
        {value: 14, text: '14'},
        {value: 15, text: '15'},
        {value: 16, text: '16'},
        {value: 17, text: '17'},
        {value: 18, text: '18'},
        {value: 19, text: '19'},
        {value: 20, text: '20'},
        {value: 21, text: '21'},
        {value: 22, text: '22'},
        {value: 23, text: '23'},
        {value: 24, text: '24'},
        {value: 25, text: '25'},
        {value: 26, text: '26'},
        {value: 27, text: '27'},
        {value: 28, text: '28'},
        {value: 29, text: '29'},
        {value: 30, text: '30'},
        {value: 31, text: '31'},
        {value: -1, text: i18n.t('general.last_day')}
    ]
}

export const getActiveOptions = () => {
    return [
        { value: false, text: i18n.t('general.inactive') },
        { value: true, text: i18n.t('general.active') }
    ]
}